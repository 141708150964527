@font-face {
  font-family: "DIN";
  font-weight: normal;
  src: local("DINNextCYR-Regular"), url("https://uploads.dupixentvirtualbooth.com/uscongress/assets/Fonts/1bf8d1be-e5d2-490f-927d-8bf67961b426.woff") format("woff"), url("https://uploads.dupixentvirtualbooth.com/uscongress/assets/Fonts/1bdb82ac-585e-4a3a-9491-f3bc0dd0fe77.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "DIN";
  font-weight: bold;
  src: local("DINNextCYR-Bold"), url("https://uploads.dupixentvirtualbooth.com/uscongress/assets/Fonts/3bfb2f5f-be2a-4142-ba19-3c38f4963f92.woff") format("woff"), url("https://uploads.dupixentvirtualbooth.com/uscongress/assets/Fonts/5b3dcf7c-d381-4d6b-8ec5-eed99ead0484.woff2") format("woff2");
  font-display: block;
}

body {
  font-family: "DIN", sans-serif, Arial;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  height: 100vh;
}
